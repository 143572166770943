import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../Assets/Images/fluxtonx-logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallDevice = window.innerWidth <= 768;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Links array
  const links = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "About Us",
      href: "/about",
    },
    {
      name: "Services",
      href: "/services",
    },
    {
      name: "Portfolio",
      href: "/portfolio",
    },
    {
      name: "Team",
      href: "/team",
    },
  ];

  return (
    <div className="py-3 fixed top-2 left-0 right-0 z-10 w-11/12 mx-auto bg-[#0052CC]">
      <nav className="flex items-center justify-between px-6">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src={isSmallDevice ? logo : logo}
            alt="Logo"
            className="h-auto w-14"
          />
          <h5 className="text-sm font-bold text-white pt-3 pl-1">
            FLU<span className="text-yellow-400">X</span>TONX
          </h5>
        </Link>

        {/* Links for larger screens */}
        <div className="hidden md:flex space-x-12">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.href}
              className="text-white block text-base font-semibold py-1 px-6  hover:bg-custom-yellow rounded "
              onClick={closeMenu}
            >
              {link.name}
            </Link>
          ))}
          <Link to='/contact'>
            <button className="text-white block text-base font-semibold py-1 px-6 bg-custom-yellow hover:bg-yellow-500 rounded">
              Contact Us
            </button>
          </Link>
        </div>

        {/* Hamburger menu for mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            {isOpen ? (
              <FaTimes className="w-6 h-6" />
            ) : (
              <FaBars className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Sidebar menu for mobile */}
        <div
          className={`fixed top-16 left-0 h-full bg-custom-blue z-10 transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out md:hidden`}
        >
          <div className="p-16 space-y-6 font-semibold">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.href}
                className="text-white hover:text-custom-yellow block"
                onClick={closeMenu}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
