import React from 'react';
import { Link } from 'react-router-dom';
import mobiledev from "../Assets/Icons/app-development.png";
import webdev from "../Assets/Icons/web-development.png";
import eccomdev from "../Assets/Icons/eccomerce.png";
import digidev from "../Assets/Icons/social-media-marketing.png";
import uiuxdev from "../Assets/Icons/ui-ux.png";
import busidev from "../Assets/Icons/profit.png";



const ServiceCard = () => {
    const cards = [
        { id: 1, logo: mobiledev, title: 'Mobile Apps Development', description: 'Access an extensive range of forward-thinking mobile app development solutions to reach target audiences...' },
        { id: 2, logo: webdev, title: 'Websites Development', description: 'Get cost-effective and eye-catching web development solutions for your business to thrive... ' },
        { id: 3, logo: uiuxdev, title: 'UI/UX Desiging', description: 'Unlock the power of intuitive design with our innovative UI/UX solutions. Transform your user experience ...' },
        { id: 4, logo: eccomdev, title: 'Ecommerce Development', description: 'Delivering perfect and top-notch customer satisfaction through smoothly functioning, secure ... ' },
        { id: 5, logo: digidev, title: 'Digital Marketing', description: 'Maneuver your brand with our Marketing & SEO strategies, tactics and technical expertise to grab ... ' },
        { id: 6, logo: busidev, title: 'Business Development', description: 'Use our business development skills to grow your business and reach more customers. We offer ...' },
    ];

    return (
        <>
            <div className="container mx-auto mb-4 px-4 py-8 grid grid-cols-1 md:grid-cols-3 gap-4">
                {cards.map(card => (
                    <div
                        key={card.id}
                        className="group bg-white border border-custom-blue p-4 rounded-lg transition duration-500 bg-custom-blue hover:bg-primary-color">
                        <div className="flex items-center justify-center mb-4 space-x-4">
                        <img src={card.logo} alt={card.title} className="justify-items-start h-16 w-16  group-hover:filter group-hover:brightness-0 group-hover:invert"/>
                            <h3 className="text-lg font-bold text-black group-hover:text-white">{card.title}</h3>
                        </div>
                        <p className="mb-4 text-black group-hover:text-white">{card.description}</p>
                        <Link to={`/readmore/${card.id}`} className="text-base font-semibold text-black group-hover:text-white group-hover:underline">Read More</Link>
                    </div>
                ))}
            </div>
            <div className='flex justify-center mb-8'>
                <button
                    className="py-3 px-10 text-base font-medium text-white rounded bg-primary-color hover:bg-custom-yellow hover:text-black"
                    style={{
                        boxShadow: "inset 0px 4px 4px rgba(255, 255, 255, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    See All Services
                </button>
            </div>
        </>
    );
}

export default ServiceCard;
