// import React from 'react';
// import card1 from "../Assets/Images/card1 (1).jpeg";
// import card2 from "../Assets/Images/card1 (2).jpeg";
// import card3 from "../Assets/Images/card1 (3).jpeg";
// import card4 from "../Assets/Images/card1 (4).jpeg";
// import card5 from "../Assets/Images/card1 (5).jpeg";
// import card6 from "../Assets/Images/card1 (6).jpeg";

// const PortfolioMenuComp = () => {
//   const portfolioItems = [
//     { id: 1, category: 'Mobile Apps', image: card1, title: 'Mobile App 1' },
//     { id: 2, category: 'Mobile Apps', image: card2, title: 'Mobile App 2' },
//     { id: 3, category: 'Websites', image: card3, title: 'Website 1' },
//     { id: 4, category: 'Websites', image: card4, title: 'Website 2' },
//     { id: 5, category: 'Ecommerce', image: card5, title: 'Ecommerce 1' },
//     { id: 6, category: 'Ecommerce', image: card6, title: 'Ecommerce 2' },
//   ];

//   return (
//     <div className="container mx-auto px-4 py-4">
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
//         {portfolioItems.map(item => (
//           <div key={item.id} className="bg-white rounded-lg shadow-md overflow-hidden">
//             <img src={item.image} alt={item.title} className="w-full h-64 object-cover" />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PortfolioMenuComp;


import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import card1 from "../Assets/Images/card1 (1).jpeg";
import card2 from "../Assets/Images/candle.png";
import card3 from "../Assets/Images/resilence.png";
import card4 from "../Assets/Images/card1 (4).jpeg";
import card5 from "../Assets/Images/card1 (5).jpeg";
import card6 from "../Assets/Images/card1 (6).jpeg";

const PortfolioMenuComp = () => {
  const portfolioItems = [
    { id: 1, category: 'Mobile Apps', image: card1, title: 'Mobile App 1' },
    { id: 2, category: 'Mobile Apps', image: card2, title: 'Mobile App 2' },
    { id: 3, category: 'Websites', image: card3, title: 'Website 1' },
    { id: 4, category: 'Websites', image: card4, title: 'Website 2' },
    { id: 5, category: 'Ecommerce', image: card5, title: 'Ecommerce 1' },
    { id: 6, category: 'Ecommerce', image: card6, title: 'Ecommerce 2' },
  ];

  const NextArrow = ({ onClick }) => {
    return (
      <div 
        className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer z-10 hover:bg-gray-200 p-2 rounded-full"
        onClick={onClick}
      >
        <FaArrowRight className="text-2xl text-gray-700" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div 
        className="absolute top-1/2 left-4 transform -translate-y-1/2 cursor-pointer z-10 hover:bg-gray-200 p-2 rounded-full"
        onClick={onClick}
      >
        <FaArrowLeft className="text-2xl text-gray-700" />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="container mx-auto px-4 py-4 relative">
      <Slider {...settings}>
        {portfolioItems.map(item => (
          <div key={item.id} className="px-2">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img src={item.image} alt={item.title} className="w-full h-64 object-cover" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PortfolioMenuComp;
