import React from "react";
import { team } from "../Assets/data";

const TeamComp = () => {
    return (
        <>
            <div className="text-center mb-10">
                <h2 className="text-3xl md:text-4xl md:px-30 py-5  font-bold text-gray-800 mb-10">The Amazing Team of Us</h2>
            </div>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center">
                    {team.map((member, index) => (
                        <div className="w-full md:w-1/3 lg:w-1/4 p-4" key={index}>
                            <div className="rounded-full overflow-hidden mx-auto w-32 h-32">
                                <img
                                    src={member.img}
                                    alt={member.title}
                                    className="object-cover w-full h-full"
                                />
                            </div>
                            <div className="text-center py-4">
                                <h3 className="text-xl font-semibold">{member.title}</h3>
                                <h6 className="text-gray-600">{member.position}</h6>
                                <p className="text-gray-700">{member.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TeamComp;
