import React from 'react';
import iImage from '../Assets/Images/about-image.png';


const AboutUSComp = () => {
    return (
        <div className="container mx-auto mb-8 px-4 md:px-16 py-8 flex flex-col md:flex-row items-center justify-center gap-4">
            <div className="md:w-1/2 mt-4 flex justify-center md:justify-end">
                <img src={iImage} alt="About Us" className="w-4/5 h-auto rounded-lg " />
            </div>
            <div className="md:w-1/2">
                <h5 className="text-xl font-bold text-custom-heading mb-2">About Us</h5>
                <h2 className="text-3xl md:text-4xl font-bold text-custom-heading mb-4">Transforming Complexity 
                into simplicity.</h2>
                <p className="text-lg text-gray-900 mb-4">
                    FluxtonX is a complete software development company leading in this industry
                    for over 5+ years. We are here to help clients turn their thoughts into
                    reality. We have been serving people globally with expert web developers, app developers, and the latest artificial intelligence abilities.
                </p>
            </div>
        </div>
    );
}

export default AboutUSComp;
