import team_mem1 from "../Images/team/nasir.png";
import team_mem2 from "../Images/team/tehseen.png";
import team_mem3 from "../Images/team/yasir.png";
import team_mem4 from "../Images/team/abid.png";
import team_mem5 from "../Images/team/aqib.png";
import team_mem6 from "../Images/team/chashman.png";
import team_mem7 from "../Images/team/zahad.png";
import team_mem8 from "../Images/team/amanpreet.png";
import team_mem9 from "../Images/team/shahzeb.png";

import html from "../Images/technologies/HTML.png";
import css from "../Images/technologies/CSS.png";
import javascript from "../Images/technologies/JavaScript.png";
import react from "../Images/technologies/react.png";

export const team = [
    {
      img: team_mem1,
      title: "Muhammad Nasir (CEO)",
      position: "Business Developer",
      desc: "",
    },
    {
      img: team_mem2,
      title: "Muhammad Tehseen",
      position: "CTO Team Leader",
      desc: "",
    },
    {
      img: team_mem3,
      title: "Yasir Khan",
      position: "Flutter Developer",
      desc: "",
    },
    {
      img: team_mem4,
      title: "Muhammad Abid",
      position: "Flutter Developer",
      desc: "",
    },
    {
      img: team_mem5,
      title: "Aaqib Khan",
      position: "UI/UX Designer",
      desc: "",
    },
    {
      img: team_mem6,
      title: "Chashman Khan",
      position: "Chief Designer",
      desc: "",
    },
    {
      img: team_mem7,
      title: "Muhammad Zahad",
      position: "Graphic Designer",
      desc: "",
    },  {
      img: team_mem8,
      title: "Amanpreet Kaur",
      position: "React Native Developer",
      desc: "",
    },
    {
      img: team_mem9,
      title: "Muhammad Shahzeb",
      position: "Amazon Wholesale Expert",
      desc: "",
    },
  ];

  export const technologies = [
    {
      img: html,
      alt: "logo",
    },
    {
      img: css,
      alt: "logo",
    },
    {
      img: javascript,
      alt: "logo",
    },
    {
      img: react,
      alt: "logo",
    }
  ];