import React from 'react';
import logo from "../Assets/Images/logos.svg"
import { Link } from 'react-router-dom';

// Define your data
const services = [
    "Mobile Apps Development",
    "Websites Development",
    "UI UX Designing",
    "Business Development"
];

const socialMediaLinks = [
    { name: "LinkedIn", url: "https://www.linkedin.com/company/103600325/" },
    { name: "Facebook", url: "https://www.facebook.com/profile.php?id=61560691796682" },
    { name: "Twitter", url: "" },
    { name: "Instagram", url: "" }
];

const contactInformation = [
    { name: "Email", value: "info@fluxtonx.com" },
    { name: "Address", value: "SpeenZar, 3rd floor, Office D2, Peshawar Pakistan." },
    { name: "Phone", value: "+923451184105" }
];

const Footer = () => {
    return (
        <footer className="relative mt-20 bg-[#0052CC]">
            <div className="relative mx-auto max-w-screen-xl space-y-8 px-8 py-16 sm:px-12 lg:space-y-16 lg:px-16">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    <div>
                        <Link to="/" className="flex items-center">
                            {/* Your logo */}
                            <img src={logo} alt='logo' className="ml-2 h-12 w-16 text-white" />
                        </Link>
                        <p className="mt-4 max-w-xs text-white">
                            Empowering Growth with Smart Strategies and Innovative Solutions.
                        </p>
                        {/* <div className="mt-6">
                            <form className="flex">
                                <input type="email" placeholder="Enter your email" className="text-base font-medium text-custom-text px-4 py-3 rounded-l-md focus:outline-none" />
                                <button type="submit" className="bg-[#064299] text-white px-4 py-2 rounded-r-md hover:bg-custom-yellow hover:text-white hover:transition hover:duration-500">Subscribe</button>
                            </form>
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3">
                        <div>
                            <p className="font-semibold text-white text-xl">Our Services</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                {services.map((service, index) => (
                                    <li key={index}>
                                        <a href="/#" className="text-white transition hover:opacity-75">{service}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold text-white text-xl">Follow Us</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                {socialMediaLinks.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.url} className="text-white transition hover:opacity-75">{link.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <p className="font-semibold text-xl text-white">Contact Information</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                {contactInformation.map((info, index) => (
                                    <li key={index}>
                                        <span className="text-white font-semibold">{info.name}: </span>
                                        <a href="/#" className="text-white transition hover:opacity-75">{info.value}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>   
            </div>
            <hr />
            <p className="text-xs text-white p-4 mr-8 text-end">Copyright &copy; 2024, FluxtonX All rights reserved.</p>
        </footer>
    );
};

export default Footer;