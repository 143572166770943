import React, { useState } from "react";
import emailjs from "emailjs-com";
import backgroundImage from "../Assets/Images/background-image1.png";
import rightImage from "../Assets/Images/illustration-1.png";

const HeroSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the fields before sending
    if (!formData.name || !formData.email || !formData.contact || !formData.message) {
      alert("Please fill in all fields.");
      return;
    }

    emailjs
      .send(
        'service_7j7f108',
        'template_12gqbmn',
        formData,
        'hktsWWWvxGeJ-Px0q'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert("Message sent successfully!");
        setFormData({ name: "", email: "", contact: "", message: "" });
      })
      .catch((error) => {
        console.log('FAILED...', error);
        alert("Error sending message. Please try again.");
      });
  };

  return (
    <div
      className="bg-cover bg-center flex items-center justify-center h-screen"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto px-4 md:px-16 flex flex-col md:flex-row items-center">
        <div className="md:w-2/5 text-white pt-4 text-center md:text-left">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-white">
            Grow your business faster with
          </h1>
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-custom-yellow">
            FluxtonX
          </h1>
          <p className="text-base md:text-base mb-6 text-white">
            FluxtonX is a leading software company with everything you need to
            run & grow your business.
          </p>
          <button
            className="py-2 px-10 text-base font-medium text-white rounded bg-[#064299] hover:bg-custom-yellow hover:text-black"
            style={{
              boxShadow:
                "0px 4px 4px rgba(255, 255, 255, 0.3), 0px 4px 4px rgba(255, 255, 255, 0.25)",
            }}
          >
            Start Now
          </button>
        </div>
        <div className="md:w-2/5 ml-auto">
          <form id="contact" className="flex justify-center pt-8" onSubmit={handleSubmit}>
            <div className="bg-white p-6 rounded-lg shadow-lg md:w-10/12">
              <h2 className="text-lg font-semibold text-center text-[#064299] mb-4">
                Request for Service
              </h2>
              <div className="mb-2">
                <label className="text-sm font-normal text-[#7D7A7A]">
                  Name
                </label>
                <input
                  type="text"
                  className="w-full border border-[##DDDDDD] rounded px-3 py-2"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="text-sm font-normal text-[#7D7A7A]">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full border border-[##DDDDDD] rounded px-3 py-2"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="text-sm font-normal text-[#7D7A7A]">
                  Contact No
                </label>
                <input
                  type="text"
                  className="w-full border border-[##DDDDDD] rounded px-3 py-2"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label className="text-sm font-normal text-[#7D7A7A]">
                  Message
                </label>
                <textarea
                  className="w-full border border-[##DDDDDD] rounded px-3 py-2"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-10 text-base font-medium bg-primary-color text-white rounded hover:bg-transparent hover:text-sky-500 hover:border hover:border-sky-500"
                style={{
                  boxShadow: "inset 0px 4px 4px rgba(255, 255, 255, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
