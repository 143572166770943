import React from 'react';
import HeroSection from '../Components/HeroSection';
import ServiceCopm from '../Components/ServiceCopm';
import AboutUSComp from '../Components/AboutUSComp';
import Counter from '../Components/Counter';
import PortfolioComp from '../Components/PortfolioComp';
import TestimonialComp from '../Components/TestimonialComp';
import TeamComp from '../Components/TeamComp';

const Home = () => {
  return (
    <>
      <HeroSection />
      <AboutUSComp/>
      <ServiceCopm/>
      <PortfolioComp/>
      <TeamComp/>
      {/* <Counter/>    */}
      {/* <TestimonialComp /> */}
      
    </>
  );
}

export default Home;
