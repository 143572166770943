import React from "react";
import ServiceCard from "./ServiceCard";

const ServiceCopm = () => {
  return (
    <>
      <div className="container items-center pt-16 pb-6 bg-white">
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-custom-heading mb-4 ">
            Services We Offer
          </h2>
          <p className="text-sm text-custom-text">
            FluxtonX offers a range of services to help our clients i.e.
            Businesses and Individuals Design, <br />
            Develop and Maintain Mobile and Web applications. Below, 
            you'll find an overview <br />
            of the key services we provide.
          </p>
        </div>
      </div>
      <div className="container mx-auto px-4 md:px-16">
        <ServiceCard />
      </div>
    </>
  );
};

export default ServiceCopm;
