import React from 'react';
import PortfolioMenuComp from './PortfolioMenuComp';

const PortfolioComp = () => {
    return (
        <>
        <div className="container mx-auto px-4 md:px-16 py-4 flex flex-col md:flex-row items-center justify-center">
            <div className="flex flex-col mb-4 md:mb-0">
                <h5 className="text-3xl md:text-4xl font-bold  text-custom-heading mb-2">PORTFOLIO</h5>
                {/* <h2 className="text-xl font-bold text-custom-heading">Awesome Portfolio</h2> */}
            </div>
        </div>
        <div className="container mx-auto px-4 md:px-10">
            <PortfolioMenuComp/>
        </div>
        </>
    );
}

export default PortfolioComp;
